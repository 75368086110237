import { isNil, map, sortBy, zip } from 'lodash-es';
import type { Resolvable, SortedExpression } from '@trackback/widgets';
import {
  appendContext,
  combineLatestArray,
  DataChangeCallback,
  ParseOptions,
  Parser,
} from '../parser';
import { createNamedExpressionResolver } from '../types';

const sortRecursive = <T>(
  parser: Parser,
  options: ParseOptions,
  onData: DataChangeCallback<T[]>,
  items: T[],
  sortValueFactory: Resolvable<number | string>,
  contextKey: string,
  recursionProperty?: string
): (() => void) | void => {
  return combineLatestArray<number | string>(
    items.map(
      item => onChange =>
        parser.parseWithCallback(
          sortValueFactory,
          {
            error: onChange.error,
            next: sortValue => onChange.next(sortValue),
          },
          appendContext(options, { [contextKey]: item })
        )
    ),
    {
      error: onData.error,
      next: sortValues => {
        const sortedItems = map(sortBy(zip(items, sortValues), 1), 0) as T[];
        if (isNil(recursionProperty)) {
          return onData.next(sortedItems);
        }
        return combineLatestArray<T>(
          sortedItems.map(sortedItem => onChange => {
            if (
              typeof sortedItem === 'object' &&
              sortedItem !== null &&
              recursionProperty in sortedItem &&
              Array.isArray(
                (sortedItem as Record<string, unknown>)[recursionProperty]
              )
            ) {
              return sortRecursive(
                parser,
                options,
                {
                  error: onChange.error,
                  next: sortedChildren =>
                    onChange.next({
                      ...sortedItem,
                      [recursionProperty]: sortedChildren,
                    }),
                },
                sortedItem[recursionProperty],
                sortValueFactory,
                contextKey,
                recursionProperty
              );
            } else {
              return onChange.next(sortedItem);
            }
          }),
          onData
        );
      },
    }
  );
};

export const sortResolver = createNamedExpressionResolver<SortedExpression>(
  'sort',
  (
    parser,
    options,
    onData,
    [items, sortValueFactory, contextKey, recursionProperty]
  ) => {
    return parser.parseArray(
      [items, contextKey ?? 'item', recursionProperty] as const,
      {
        error: onData.error,
        next: ([
          resolvedItems,
          resolvedContextKey,
          resolvedRecursionProperty,
        ]) => {
          if (!resolvedItems || !Array.isArray(resolvedItems)) {
            return onData.next(resolvedItems);
          }
          return sortRecursive(
            parser,
            options,
            onData,
            resolvedItems,
            sortValueFactory,
            resolvedContextKey,
            resolvedRecursionProperty
          );
        },
      },
      options
    );
  }
);
