import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { Register } from '@app/utils/type-registry';
import {
  ProgressBarInput,
  ProgressBarOutput,
  Resolved,
} from '@trackback/widgets';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { BaseWidgetComponent } from '@app/widgets/base-widget.component';

@Register('progressbar')
@Component({
  selector: 'tb-progress-bar',
  template: `
    <mat-progress-bar
      class="example-margin"
      [color]="(parsedInput$ | async).color"
      [mode]="(parsedInput$ | async).mode"
      [value]="(parsedInput$ | async).value"
      [bufferValue]="(parsedInput$ | async).bufferValue"
      [style.height.px]="(parsedInput$ | async).height"
    >
    </mat-progress-bar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
})
export class ProgressBarComponent extends BaseWidgetComponent<
  ProgressBarInput,
  ProgressBarOutput
> {
  readonly parsedInput$: Observable<Resolved<ProgressBarInput>> =
    this.input$.pipe(switchMap(this.parse.bind(this)), shareReplay());
}
