import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import {
  DatepickerFieldInput,
  DatepickerFieldOutput,
} from '@trackback/widgets';
import { parseISO } from 'date-fns';
import { map } from 'rxjs/operators';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Register('DatepickerField')
@Component({
  selector: 'tb-datepicker-field',
  templateUrl: './datepicker-field.component.html',
  styleUrls: ['./datepicker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyFormFieldModule,
    NgIf,
    MatLegacyInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class DatepickerFieldComponent
  extends BaseFormFieldWidgetComponent<
    DatepickerFieldInput,
    DatepickerFieldOutput
  >
  implements OnInit
{
  public _minDate: Date;
  public _maxDate: Date;

  constructor(injector: Injector) {
    super(injector);
  }

  protected formatDefaultValue(next?: string | null): Date | undefined | null {
    if (typeof next === 'string') {
      const date = parseISO(next);
      date.setHours(0, 0, 0, 0);
      return date;
    } else if (next === undefined || next === null) {
      return next;
    }
    return undefined;
  }

  protected formatOutputValue(next?: Date): string {
    if (next) {
      return new Date(
        Date.UTC(
          next.getFullYear(),
          next.getMonth(),
          next.getDate(),
          0,
          0,
          0,
          0
        )
      ).toISOString();
    }
    return '';
  }

  async ngOnInit() {
    await super.ngOnInit();
    if (this.input.minDate) {
      this.parse(this.input.minDate)
        .pipe(map(minDate => this.formatDefaultValue(minDate)))
        .subscribe(minDate => {
          this._minDate = minDate;
        });
    }
    if (this.input.maxDate) {
      this.parse(this.input.maxDate)
        .pipe(map(maxDate => this.formatDefaultValue(maxDate)))
        .subscribe(maxDate => (this._maxDate = maxDate));
    }
  }
}
