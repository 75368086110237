<ng-container [ngSwitch]="input.style | parse: context | async">
  <ng-container *ngSwitchCase="'basic'">
    <a
      mat-button
      *ngIf="input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [href]="input.url | parse: context | async"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
    <a
      mat-button
      *ngIf="!input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [routerLink]="input.url | parse: context | async"
      [queryParams]="input.queryParams | parse: context | async"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'raised'">
    <a
      mat-raised-button
      *ngIf="input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [href]="input.url | parse: context | async"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
    <a
      mat-raised-button
      *ngIf="!input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [routerLink]="input.url | parse: context | async"
      [queryParams]="input.queryParams | parse: context | async"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'stroked'">
    <a
      mat-stroked-button
      *ngIf="input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [href]="input.url | parse: context | async"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
    <a
      mat-stroked-button
      *ngIf="!input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [routerLink]="input.url | parse: context | async"
      [queryParams]="input.queryParams | parse: context | async"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'flat'">
    <a
      mat-flat-button
      *ngIf="input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [href]="input.url | parse: context | async"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
    <a
      mat-flat-button
      *ngIf="!input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [routerLink]="input.url | parse: context | async"
      [queryParams]="input.queryParams | parse: context | async"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'icon'">
    <a
      mat-icon-button
      *ngIf="input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [href]="input.url | parse: context | async"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
    <a
      mat-icon-button
      *ngIf="!input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [routerLink]="input.url | parse: context | async"
      [queryParams]="input.queryParams | parse: context | async"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'fab'">
    <a
      mat-fab
      *ngIf="input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [href]="input.url | parse: context | async"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
    <a
      mat-fab
      *ngIf="!input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [routerLink]="input.url | parse: context | async"
      [queryParams]="input.queryParams | parse: context | async"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'mini-fab'">
    <a
      mat-mini-fab
      *ngIf="input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [href]="input.url | parse: context | async"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
    <a
      mat-mini-fab
      *ngIf="!input.external"
      [color]="color | parse: context | async"
      [disabled]="input.disabled | parse: context | async"
      [routerLink]="input.url | parse: context | async"
      [queryParams]="input.queryParams | parse: context | async"
    >
      <ng-container *ngTemplateOutlet="hyperlinkContent"></ng-container>
    </a>
  </ng-container>
</ng-container>

<ng-template #hyperlinkContent>
  <mat-icon *ngIf="input.icon | parse: context | async as icon">
    {{ icon }}
  </mat-icon>
  <span *ngIf="input.text | parse: context | async as text"> {{ text }} </span>
</ng-template>
