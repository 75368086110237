import type { AliasExpression } from '@trackback/widgets';
import { Observable } from 'rxjs';
import { createNoArgsObservableNamedExpressionResolver } from '../types';

export const createAliasResolver = (
  aliases$: Observable<Record<string, string>>
) =>
  createNoArgsObservableNamedExpressionResolver<AliasExpression>(
    'aliases',
    aliases$
  );
