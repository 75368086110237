import {
  DefaultPageInput,
  DirectionalLayoutInput,
  HyperlinkInput,
  IconInput,
  TextInput,
  globalProperty,
} from '@trackback/widgets';

export const DEFAULT_CLIENT_ERROR_WIDGET = {
  id: 'client-error-page',
  type: 'DefaultPage',
  mainContentCentered: true,
  mainContentWidget: {
    type: 'DirectionalLayout',
    orientation: 'vertical',
    align: {
      mainAxis: 'center',
      crossAxis: 'center',
    },
    widgets: [
      {
        type: 'Icon',
        value: 'error',
        color: 'warn',
        size: 48,
      } as IconInput,
      {
        type: 'Text',
        text: 'An application error has occurred. If this error persists, please contact support@trackback.net',
        style: 'title',
      } as TextInput,
    ],
  } as DirectionalLayoutInput,
} as DefaultPageInput;

export const DEFAULT_NOT_FOUND_WIDGET = {
  id: 'not-found-page',
  type: 'DefaultPage',
  mainContentCentered: true,
  mainContentWidget: {
    type: 'DirectionalLayout',
    orientation: 'vertical',
    align: {
      mainAxis: 'center',
      crossAxis: 'center',
    },
    widgets: [
      {
        type: 'Icon',
        value: 'error',
        color: 'warn',
        size: 48,
      } as IconInput,
      {
        type: 'Text',
        text: 'This is not the page you are looking for',
        style: 'title',
      } as TextInput,
    ],
  } as DirectionalLayoutInput,
} as DefaultPageInput;

export const DEFAULT_SERVER_ERROR_WIDGET = {
  id: 'server-error-page',
  type: 'DefaultPage',
  mainContentCentered: true,
  mainContentWidget: {
    type: 'DirectionalLayout',
    orientation: 'vertical',
    align: {
      mainAxis: 'center',
      crossAxis: 'center',
    },
    widgets: [
      {
        type: 'Icon',
        value: 'error',
        color: 'warn',
        size: 48,
      } as IconInput,
      {
        type: 'Text',
        text: 'Unknown Server Error',
        style: 'title',
      } as TextInput,
    ],
  } as DirectionalLayoutInput,
} as DefaultPageInput;

export const DEFAULT_UNAUTHENTICATED_ERROR_WIDGET = {
  id: 'unauthenticated-page',
  type: 'DefaultPage',
  mainContentCentered: true,
  mainContentWidget: {
    type: 'DirectionalLayout',
    orientation: 'vertical',
    align: {
      mainAxis: 'center',
      crossAxis: 'center',
    },
    widgets: [
      {
        type: 'Icon',
        value: 'lock',
        color: {
          palette: 'primary',
          hue: 900,
        },
        size: 48,
      } as IconInput,
      {
        type: 'Text',
        text: 'Unauthenticated',
        style: 'title',
        color: {
          palette: 'primary',
          hue: 900,
        },
      } as TextInput,
      {
        type: 'Hyperlink',
        style: 'raised',
        color: 'accent',
        text: 'Sign in',
        url: `/login`,
        queryParams: {
          redirect_after_login: globalProperty('router.state.url'),
        },
      } as HyperlinkInput,
    ],
  } as DirectionalLayoutInput,
} as DefaultPageInput;

export const DEFAULT_UNAUTHORISED_ERROR_WIDGET = {
  id: 'unauthorised-page',
  type: 'DefaultPage',
  mainContentCentered: true,
  mainContentWidget: {
    type: 'DirectionalLayout',
    orientation: 'vertical',
    align: {
      mainAxis: 'center',
      crossAxis: 'center',
    },
    widgets: [
      {
        type: 'Icon',
        value: 'error',
        color: 'warn',
        size: 48,
      } as IconInput,
      {
        type: 'Text',
        text: 'You do not have the permissions to access this resource',
        style: 'title',
      } as TextInput,
    ],
  } as DirectionalLayoutInput,
} as DefaultPageInput;
