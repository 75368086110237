import type { ChangecaseExpression } from '@trackback/widgets';
import { createSimpleNamedExpressionResolver } from '../types';

export const changeCaseResolver =
  createSimpleNamedExpressionResolver<ChangecaseExpression>(
    'changeCase',
    (value, format) => {
      switch (format) {
        case 'upper':
          return value.toUpperCase();
        case 'lower':
          return value.toLowerCase();
      }
    }
  );
