import { LayoutOrientation } from '@app/models/layout-orientation.model';
import { Register } from '@app/utils/type-registry';
import { LayoutSize } from '@trackback/widgets';

@Register('SetActiveClientId')
export class SetActiveClientId {
  static readonly type = '[Shared.Layout] Set Active ClientId';

  constructor(public clientId: number) {}
}

export class SetSize {
  static readonly type = '[Shared.Layout] Set Size';

  constructor(public size: LayoutSize) {}
}

export class SetOrientation {
  static readonly type = '[Shared.Layout] Set Orientation';

  constructor(public orientation: LayoutOrientation) {}
}
