/**
 * Base model that all errors in the application should conform to.
 * Errors are immutable.
 */
export interface ErrorModel extends Error {
  readonly developerMessage?: string;
}

export class AppError implements ErrorModel {
  readonly name: string;
  readonly message: string;

  constructor(
    code: string,
    messageTranslationKey?: string,
    readonly developerMessage?: string
  ) {
    this.name = code;
    this.message =
      messageTranslationKey || DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR;
  }

  toJSON() {
    return JSON.stringify({
      name: this.name,
      message: this.message,
      developerMessage: this.developerMessage,
    });
  }
}

export enum DEFAULT_ERROR_TRANSLATION_KEYS {
  APPLICATION_ERROR = 'application_error',
}
