<mat-form-field>
  <mat-label
    *ngIf="input.label | parse: context | async as label"
    [matTooltip]="(input.tooltip | parse: context | async)?.text"
    matTooltipShowDelay="500"
    >{{ label }}</mat-label
  >
  <mat-chip-list
    [formControl]="_formControl"
    [placeholder]="input.placeholder | parse: context | async"
    [required]="input.required"
    [aria-orientation]="input.orientation"
    [ngClass]="input.orientation === 'vertical' ? 'mat-chip-list-stacked' : ''"
    [selectable]="true"
    [multiple]="input.multiple"
    (blur)="onBlur($event)"
  >
    <mat-chip
      *ngFor="let option of parsedOptions$ | async"
      color="primary"
      #chip="matChip"
      (click)="
        _formControl.disabled || option.disabled
          ? null
          : chip.toggleSelected(true)
      "
      (selectionChange)="removePreviousValue()"
      [value]="option.value"
      [disabled]="option.disabled"
      >{{ option.label | parse: context | async }}</mat-chip
    >
  </mat-chip-list>
  <mat-hint></mat-hint>
  <mat-error *ngIf="firstFormControlError as error">
    {{ error.errorMessage | parse: context | async }}
  </mat-error>
</mat-form-field>
