import { Observable } from 'rxjs';
import type { OutputsExpression } from '@trackback/widgets';
import { createNoArgsObservableNamedExpressionResolver } from '../types';

export const createOutputResolver = <
  T extends Record<string, Record<string, unknown>>,
>(
  outputs$: Observable<T>
) =>
  createNoArgsObservableNamedExpressionResolver<OutputsExpression>(
    'outputs',
    outputs$
  );
