import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { CheckBoxFieldInput, CheckBoxFieldOutput } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { _MatCheckboxRequiredValidatorModule } from '@angular/material/checkbox';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

@Register('CheckBoxField')
@Component({
  selector: 'tb-check-box-field',
  templateUrl: './check-box-field.component.html',
  styleUrls: ['./check-box-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyCheckboxModule,
    _MatCheckboxRequiredValidatorModule,
    ReactiveFormsModule,
    NgIf,
    MatLegacyFormFieldModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class CheckBoxFieldComponent
  extends BaseFormFieldWidgetComponent<CheckBoxFieldInput, CheckBoxFieldOutput>
  implements OnInit {}
