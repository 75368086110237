import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { LayoutOrientation } from '@app/models/layout-orientation.model';
import * as Actions from './layout.actions';
import { LayoutSize } from '@trackback/widgets';

export interface LayoutStateModel {
  activeClientId: number | null;
  orientation?: LayoutOrientation;
  size?: LayoutSize;
}

export const InitialLayoutStateModel: LayoutStateModel = {
  activeClientId: null,
  orientation: undefined,
  size: undefined,
};

@State<LayoutStateModel>({
  name: 'layout',
  defaults: InitialLayoutStateModel,
})
@Injectable()
export class LayoutState implements NgxsOnInit {
  @Selector()
  static getActiveClientId(state: LayoutStateModel) {
    return state.activeClientId;
  }

  @Selector()
  static getOrientation(state: LayoutStateModel) {
    return state.orientation;
  }

  @Selector()
  static getSize(state: LayoutStateModel) {
    return state.size;
  }

  static isSize(size: LayoutSize) {
    return createSelector([LayoutState.getSize], actualSize => {
      return size === actualSize;
    });
  }

  @Action(Actions.SetActiveClientId)
  setActiveClientId(
    { patchState }: StateContext<LayoutStateModel>,
    { clientId }: Actions.SetActiveClientId
  ) {
    return patchState({
      activeClientId: clientId,
    });
  }

  @Action(Actions.SetOrientation)
  setOrientation(
    { patchState }: StateContext<LayoutStateModel>,
    { orientation }: Actions.SetOrientation
  ) {
    return patchState({
      orientation,
    });
  }

  @Action(Actions.SetSize)
  setSize(
    { patchState }: StateContext<LayoutStateModel>,
    { size }: Actions.SetSize
  ) {
    return patchState({
      size,
    });
  }

  constructor(private breakpointObserver: BreakpointObserver) {}

  private observeBreakpoint(
    mediaQueries: string[],
    operator: 'or' | 'and' = 'or'
  ) {
    return this.breakpointObserver.observe(
      operator === 'and' ? mediaQueries.join(' allTruthyIn ') : mediaQueries
    );
  }

  ngxsOnInit({ dispatch }: StateContext<LayoutStateModel>) {
    this.observeBreakpoint([Breakpoints.XSmall]).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetSize('xsmall'));
      }
    });

    this.observeBreakpoint([Breakpoints.Small]).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetSize('small'));
      }
    });

    this.observeBreakpoint([Breakpoints.Medium]).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetSize('medium'));
      }
    });

    this.observeBreakpoint([Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      state => {
        if (state.matches) {
          dispatch(new Actions.SetSize('large'));
        }
      }
    );

    this.observeBreakpoint(['(orientation: landscape)']).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetOrientation('landscape'));
      }
    });

    this.observeBreakpoint(['(orientation: portrait)']).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetOrientation('portrait'));
      }
    });
  }
}
