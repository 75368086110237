<mat-toolbar [color]="color">
  <mat-toolbar-row *ngFor="let row of input.rows">
    <div
      class="toolbar-content-wrapper"
      [style.paddingLeft]="paddingLeft"
      [style.paddingRight]="paddingRight"
      [style.paddingTop]="paddingTop"
      [style.paddingBottom]="paddingBottom"
    >
      <ng-container [dynamicWidget]="row" [context]="context"></ng-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
