import type { LocalWidgetIdExpression } from '@trackback/widgets';
import { Observable } from 'rxjs';
import { ParseOptions } from '../parser';
import { createNamedExpressionResolver } from '../types';

export const createLocalWidgetIdResolver = (
  getLocalWidgetId: (options: ParseOptions) => string | Observable<string>
) =>
  createNamedExpressionResolver<LocalWidgetIdExpression>(
    'localWidgetId',
    (_parse, options, onData) => {
      const widgetId = getLocalWidgetId(options);
      if (widgetId instanceof Observable) {
        const subscription = widgetId.subscribe(onData.next, onData.error);
        return subscription.unsubscribe.bind(subscription);
      } else {
        return onData.next(widgetId);
      }
    }
  );
