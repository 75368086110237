<div class="mat-exp-table">
  <mat-accordion displayMode="flat" multi class="mat-table">
    <section
      matSort
      class="mat-elevation-z2 mat-header-row mat-header-row-expansion"
    >
      <span
        *ngFor="let header of headers$ | async"
        class="mat-header-cell mat-header-cell-expansion"
      >
        <ng-container *ngIf="isString(header) || isExpression(header); else widgetHeader">
          {{ header | parse: context | async }}
        </ng-container>
        <ng-template #widgetHeader>
          <ng-container [dynamicWidget]="header" [context]="context" />
        </ng-template>
      </span>
    </section>
    <mat-expansion-panel
      *ngFor="let data of input.tableData | parse: context | async"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="input.panelHeaderHeight | parse: context | async"
        [expandedHeight]="input.panelContentHeight | parse: context | async"
        class="mat-row"
      >
        <div
          class="mat-cell mat-cell-expansion"
          *ngFor="let column of columns$ | async"
        >
          <ng-container *ngIf="isString(column); else widgetColumn">
            <span class="mat-cell-expansion-text">{{ data[column] }}</span>
          </ng-container>
          <ng-template #widgetColumn>
            <ng-container
              *ngIf="
                mergeContexts(
                  context,
                  wrapContext(data, input?.tableDataAlias || 'expansionRow')
                ) as mergedContext
              "
            >
              <ng-container
                [dynamicWidget]="column"
                [context]="mergedContext"
              ></ng-container>
            </ng-container>
          </ng-template>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container
          *ngIf="
            mergeContexts(
              context,
              wrapContext(data, input?.tableDataAlias || 'expansionRow')
            ) as mergedContext
          "
        >
          <ng-container
            [dynamicWidget]="widgetContent$ | async"
            [context]="mergedContext"
          ></ng-container>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
