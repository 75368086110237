import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DEFAULT_CLIENT_ERROR_WIDGET } from './models/default-widgets';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { TranslationState } from '@app/state/translations/translation.state';
import packageJSON from '../../package.json';

@Component({
  selector: 'tb-root',
  template: `
    @if (languageInitialized$ | async) {
    <router-outlet />
    } @else { @if (translationError$ | async; as error) {
    <ng-container [dynamicWidget]="errorWidget" />
    } @else {
    <div
      style="display:flex; flex-direction: column; justify-content:center; align-items:center; height:100%;"
    >
      <div
        style="color: #28232A; font-size: 24pt; font-weight: 600; font-family: sans-serif"
      >
        TrackBack
      </div>
      <div
        style="position:relative; margin-top: 25px; height:18px; width:68px;"
      >
        <div
          style="position:absolute; top:0; left:0; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both; -webkit-animation-delay:-0.32s; animation-delay:-0.32s;"
        ></div>
        <div
          style="position:absolute; top:0; left:25px; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both; -webkit-animation-delay:-0.16s; animation-delay:-0.16s;"
        ></div>
        <div
          style="position:absolute; top:0; left:50px; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both;"
        ></div>
      </div>
    </div>
    } }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AsyncPipe, RouterOutlet, DynamicWidgetDirective],
})
export class AppComponent {
  @Select(TranslationState.isInitialized)
  languageInitialized$: Observable<boolean>;

  @Select(TranslationState.getLoadingError)
  translationError$: Observable<Error>;

  readonly errorWidget = DEFAULT_CLIENT_ERROR_WIDGET;
  @HostBinding('attr.data-version')
  readonly APP_VERSION = packageJSON.version;
}
