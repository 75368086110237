import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Register } from '@app/utils/type-registry';
import { Observable, of } from 'rxjs';
import { BaseWidgetComponent } from '../base-widget.component';
import {
  DialogInput,
  DialogOutput,
  LocalActionModel,
} from '@trackback/widgets';
import { DynamicWidgetDirective } from '../../directives/dynamic-widget.directive';

@Register('Dialog')
@Component({
  selector: 'tb-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [DynamicWidgetDirective],
})
export class DialogComponent extends BaseWidgetComponent<
  DialogInput,
  DialogOutput
> {
  @HostBinding('style.paddingTop')
  paddingTop?: string;

  @HostBinding('style.paddingRight')
  paddingRight?: string;

  @HostBinding('style.paddingBottom')
  paddingBottom?: string;

  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) scope: DialogData,
    public _dialogRef: MatDialogRef<DialogComponent>
  ) {
    super(injector);
    this.input = scope.input;
    this.context = scope.context;
  }

  handleCloseAction(action: LocalActionModel): Observable<any> {
    this._dialogRef.close(action.payload);
    return of(action.payload);
  }
}

type DialogData = {
  input: DialogInput;
  context: Record<string, any>;
};
