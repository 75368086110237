import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  HostBinding,
  Injector,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { TextInput, TextOutput } from '@trackback/widgets';
import { startWith, pairwise } from 'rxjs/operators';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@Register('Text')
@Component({
  selector: 'tb-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatLegacyTooltipModule, AsyncPipe, ParsePipe],
})
export class TextComponent
  extends BaseWidgetComponent<TextInput, TextOutput>
  implements OnInit, OnDestroy
{
  @HostBinding('style.paddingTop')
  paddingTop?: string;

  @HostBinding('style.paddingRight')
  paddingRight?: string;

  @HostBinding('style.paddingBottom')
  paddingBottom?: string;

  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  @HostBinding('style.textTransform')
  get textTransform() {
    if (this.input && this.input.textTransform) {
      return this.input.textTransform;
    }
    return null;
  }

  @HostBinding('style.textAlign')
  get textAlign() {
    if (this.input && this.input.textAlign) {
      return this.input.textAlign;
    }
    return null;
  }

  @HostBinding('style.fontWeight')
  fontWeight: number;

  @HostBinding('style.letterSpacing')
  get letterSpacing() {
    if (this.input && this.input.letterSpacing) {
      return this.input.letterSpacing;
    }
    return null;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  setTextStyleActive(style: string, flag: boolean) {
    if (style) {
      const cls = `mat-${style}`;
      if (flag) {
        this.addStyleClasses(cls);
      } else {
        this.removeStyleClasses(cls);
      }
    }
  }

  async ngOnInit() {
    await super.ngOnInit();

    if (this.input && this.input.fontWeight) {
      this.parse(this.input.fontWeight).subscribe((fontWeight: number) => {
        this.fontWeight = fontWeight;
        this._cd.markForCheck();
      });
    }

    if (!this.input.wrap) {
      this.addStyleClasses('no-wrap');
    }

    if (this.input.style) {
      this.parse(this.input.style)
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]) => {
          if (prev) {
            this.setTextStyleActive(String(prev), false);
          }
          if (next) {
            this.setTextStyleActive(String(next), true);
          }
        });
    }
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }
}
