import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Register } from '@app/utils/type-registry';
import {
  HyperlinkInput,
  HyperlinkOutput,
  MatColorDefinitionModel,
} from '@trackback/widgets';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  AsyncPipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';

@Register('Hyperlink')
@Component({
  selector: 'tb-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgIf,
    MatLegacyButtonModule,
    NgTemplateOutlet,
    RouterLink,
    MatIconModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class HyperlinkComponent
  extends BaseWidgetComponent<HyperlinkInput, HyperlinkOutput>
  implements OnInit, OnDestroy
{
  color: ThemePalette | null;

  private readonly activatedRoute = this.injector.get(ActivatedRoute);

  constructor(private readonly injector: Injector) {
    super(injector);
  }

  protected setColorActive(
    _type: 'foreground' | 'background',
    color: MatColorDefinitionModel
  ) {
    this.color = typeof color === 'object' ? color.palette : color;
    this._cd.markForCheck();
  }

  async ngOnInit() {
    await super.ngOnInit();

    combineLatest([this.parse(this.input.url), this.activatedRoute.url])
      .pipe(
        map(([parsedUrl, segments]) => {
          const activeUrl =
            '/' +
            (segments.length > 0
              ? segments.map(segment => segment.path).join('/')
              : '');
          return activeUrl === parsedUrl;
        }),
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe(active => {
        this.updateOutput({
          active,
        });
      });
  }

  ngOnDestroy(): void {
    // Skip De-registration
    this.destroy();
  }
}
