import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { IconInput, IconOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';

@Register('Icon')
@Component({
  selector: 'tb-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatIconModule, MatLegacyTooltipModule, AsyncPipe, ParsePipe],
})
export class IconComponent
  extends BaseWidgetComponent<IconInput, IconOutput>
  implements OnInit, OnDestroy
{
  @HostBinding('style.paddingTop')
  paddingTop?: string;

  @HostBinding('style.paddingRight')
  paddingRight?: string;

  @HostBinding('style.paddingBottom')
  paddingBottom?: string;

  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  size = 24;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    // Skip Registration
    this.init();

    if (this.input.size) {
      this.parse(this.input.size).subscribe(newSize => {
        this.size = Number(newSize);
        this._cd.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }
}
