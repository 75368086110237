import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { LabelRowInput, LabelRowOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';
import { DynamicWidgetDirective } from '../../directives/dynamic-widget.directive';

@Register('LabelRow')
@Component({
  selector: 'tb-label-row',
  templateUrl: './label-row.component.html',
  styleUrls: ['./label-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [DynamicWidgetDirective, AsyncPipe, ParsePipe],
})
export class LabelRowComponent
  extends BaseWidgetComponent<LabelRowInput, LabelRowOutput>
  implements OnInit, OnDestroy
{
  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip De-registration
    this.destroy();
  }
}
