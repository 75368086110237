import { Observable } from 'rxjs';
import type { NamedExpression, Resolved } from '@trackback/widgets';
import {
  Parser,
  ParseOptions,
  DataChangeCallback,
  ResolverFunction,
  Func,
} from './parser';

export interface NamedResolver<
  Definition extends NamedExpression<string, any[], any>,
> {
  (
    parser: Parser,
    options: ParseOptions,
    onData: DataChangeCallback<Definition['result']>,
    args: Definition['args']
  ): Func<[], void> | void;
  id: Definition['$'];
}

export type SimpleNamedExpressionResolveFunction<
  Definition extends NamedExpression<string, any[], any>,
> = (...args: Resolved<Definition['args']>) => Definition['result'];

export const createNamedExpressionResolver = <
  Definition extends NamedExpression<string, any[], any>,
>(
  id: Definition['$'],
  fn: ResolverFunction<Definition['args'], Definition['result']>
): NamedResolver<Definition> => Object.assign(fn, { id });

export type NamedResolverFunction<
  Definition extends NamedExpression<string, any[], any>,
> = (
  parser: Parser,
  options: ParseOptions,
  onData: DataChangeCallback<Definition['result']>,
  args: Definition['args']
) => Func<[], void> | void;

export const createSimpleNamedExpressionResolver = <
  Definition extends NamedExpression<string, any[], any>,
>(
  id: Definition['$'],
  fn: SimpleNamedExpressionResolveFunction<Definition>
): NamedResolver<Definition> =>
  Object.assign(
    (
      parser: Parser,
      options: ParseOptions,
      onDataChange: DataChangeCallback<Definition['result']>,
      args: Definition['args']
    ) =>
      parser.parseArray(
        args,
        {
          error: onDataChange.error,
          next: resolvedArgs => {
            const next = fn(...resolvedArgs);
            options.log?.(id, resolvedArgs, next);
            return onDataChange.next(next);
          },
        },
        options
      ),
    { id }
  );

export const createSimpleNamedObservableExpressionResolver = <
  Definition extends NamedExpression<string, any[], any>,
>(
  id: Definition['$'],
  fn:
    | Func<Resolved<Definition['args']>, Observable<Definition['result']>>
    | Observable<Definition['result']>
): NamedResolver<Definition> =>
  Object.assign(
    (
      parser: Parser,
      options: ParseOptions,
      onDataChange: DataChangeCallback<Definition['result']>,
      args: Definition['args']
    ) =>
      parser.parseArray(
        args,
        {
          error: onDataChange.error,
          next: resolvedArgs => {
            const subscription = (
              fn instanceof Observable ? fn : fn(...resolvedArgs)
            ).subscribe(onDataChange.next, onDataChange.error);
            return subscription.unsubscribe.bind(subscription);
          },
        },
        options
      ),
    { id }
  );

export const createNoArgsObservableNamedExpressionResolver = <
  Definition extends NamedExpression<string, any[], any>,
>(
  id: Definition['$'],
  fn:
    | Func<[], Observable<Definition['result']>>
    | Observable<Definition['result']>
): NamedResolver<Definition> =>
  Object.assign(
    (
      _parser: Parser,
      _options: ParseOptions,
      onDataChange: DataChangeCallback<Definition['result']>
    ) => {
      const subscription = (fn instanceof Observable ? fn : fn()).subscribe(
        onDataChange.next,
        onDataChange.error
      );
      return subscription.unsubscribe.bind(subscription);
    },
    { id }
  );
