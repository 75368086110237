<ng-container [ngSwitch]="input.style">
  <button
    mat-button
    *ngSwitchCase="'basic'"
    [color]="color"
    [disabled]="input.disabled | parse: context | async"
    (click)="onClick($event)"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    mat-raised-button
    *ngSwitchCase="'raised'"
    [color]="color"
    [disabled]="input.disabled | parse: context | async"
    (click)="onClick($event)"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    mat-stroked-button
    *ngSwitchCase="'stroked'"
    [color]="color"
    [disabled]="input.disabled | parse: context | async"
    (click)="onClick($event)"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    mat-flat-button
    *ngSwitchCase="'flat'"
    [color]="color"
    [disabled]="input.disabled | parse: context | async"
    (click)="onClick($event)"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    mat-icon-button
    *ngSwitchCase="'icon'"
    [color]="color"
    [disabled]="input.disabled | parse: context | async"
    (click)="onClick($event)"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    mat-fab
    *ngSwitchCase="'fab'"
    [color]="color"
    [disabled]="input.disabled | parse: context | async"
    (click)="onClick($event)"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    mat-mini-fab
    *ngSwitchCase="'mini-fab'"
    [color]="color"
    [disabled]="input.disabled | parse: context | async"
    (click)="onClick($event)"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let widget of input.menuWidgets">
      <div [dynamicWidget]="widget" [context]="context"></div>
    </ng-container>
  </mat-menu>
</ng-container>

<ng-template #buttonContent>
  <mat-progress-spinner
    *ngIf="input.loading | parse: context | async; else notLoadingContent"
    mode="indeterminate"
    [color]="input.loadingColor"
    diameter="24"
  />
  <ng-container
    *ngIf="input.contentWidget as contentWidget"
    [dynamicWidget]="contentWidget"
    [context]="context"
  ></ng-container>
  <span
    *ngIf="input.menuWidgets"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
  ></span>
</ng-template>

<ng-template #notLoadingContent>
  <mat-icon *ngIf="input.icon | parse: context | async as icon">
    {{ icon }}
  </mat-icon>
  <span *ngIf="input.text | parse: context | async as text">
    {{ text }}
  </span>
</ng-template>
