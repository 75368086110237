import { Store } from '@ngxs/store';
import { Register } from '@app/utils/type-registry';
import {
  DataInputModel,
  Expression,
  LocalActionModel,
  WidgetActionModel,
  WidgetInputModel,
} from '@trackback/widgets';
import { assign, isEqual } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  interval,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  retryWhen,
  skip,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { ACTION_DISPATCHER } from '@app/models/action-dispatcher.model';
import { WidgetDefinitionTuple } from '@app/models/widget-input.model';
import { ParserService } from '@app/services/parser.service';
import {
  DeregisterWidget,
  RegisterWidget,
} from '@app/state/widgets/widgets.actions';
import { isEmpty } from '@app/utils/is-empty';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from './index';
import { WidgetResolver } from './widget-resolver';
import { APP_CONFIG } from '@app/models/app-config.model';
import { LoadingStateCounter } from '@app/utils/loading-state-counter.class';

@Register('data', STRUCTURAL_WIDGET_TYPE_GROUP)
export class DataWidget extends WidgetResolver<
  DataInputModel<WidgetInputModel>
> {
  private id = '';

  private inputContext$ = new Subject<Record<string, any>>();

  /**
   * The object literal or binding expression passed in the input
   */
  private _data$ = new BehaviorSubject<any | Expression<any, any>>(
    this._input.data
  );

  /**
   * Returns the currently active widget, depending on the loading & error states
   */
  private _activeWidget$ = new BehaviorSubject<WidgetInputModel | undefined>(
    undefined
  );

  private _refresh$ = new BehaviorSubject<null>(null);

  /**
   * Keeps track of the loading state for the binding
   */
  private _loadingStateCounter = new LoadingStateCounter();

  /**
   * Whether this widget is currently loading
   */
  private _loading$ = this._loadingStateCounter.isLoading$;

  /**
   * The currently active error.
   */
  private _error$ = new BehaviorSubject<any>(undefined);

  /**
   * Observable representing the parsed data
   */
  private _resolvedContext$ = new BehaviorSubject<
    Record<string, any> | undefined
  >(undefined);

  /**
   * Returns the overall state of this widget.
   * This contains the current widget to be rendered as well as its context
   */
  private __state$ = new BehaviorSubject<WidgetDefinitionTuple[] | undefined>(
    undefined
  );

  private _state$ = this.__state$.pipe(filter(state => !!state)) as Observable<
    WidgetDefinitionTuple[]
  >;

  private readonly _store = this._injector.get(Store);
  private readonly _parser = this._injector.get(ParserService);
  private readonly _dispatcher = this._injector.get(ACTION_DISPATCHER);
  private readonly _config = this._injector.get(APP_CONFIG, null);
  private readonly _destroy$ = new Subject();

  readonly handleAction = (action: LocalActionModel): Observable<any> => {
    switch (action.name) {
      case 'SetData':
        this._data$.next(action.payload);
        break;
      case 'Refresh':
        this._refresh$.next(null);
        break;
      case 'AutoRefresh':
        interval(Number(action.payload) || 30000)
          .pipe(takeUntil(this._destroy$))
          .subscribe(() => this._refresh$.next(null));
        break;
      default:
        console.error(
          `Unknown action (${action.name}) on structural data widget`
        );
    }
    return of(null);
  };

  connect() {
    // Register widget to enable local actions to be dispatched against it
    this.inputContext$
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap(context => {
          return this._parser.parse(this._input.id, {
            context,
            log:
              !this._config || !this._config.PRODUCTION
                ? console.log
                : undefined,
          });
        })
      )
      .subscribe(widgetId => {
        if (widgetId) {
          this.id = String(widgetId);
          this._store.dispatch(
            new RegisterWidget(this.id, this._input.alias, this.handleAction)
          );
          this.inputContext$.pipe(take(1)).subscribe(context => {
            if (this._input.afterRegisterAction) {
              this._dispatcher
                .dispatch(
                  {
                    ...this._input.afterRegisterAction,
                    sourceWidgetId: this.id,
                  },
                  context
                )
                .toPromise();
            }
          });
        }
      });

    // Reset error on refresh
    this._loading$
      .pipe(filter(Boolean), takeUntil(this._destroy$))
      .subscribe(() => this._error$.next(false));

    combineLatest([this._loading$, this._error$, this._resolvedContext$])
      .pipe(
        skip(1),
        debounceTime(10),
        map(([isLoading, hasError, context]) => {
          if (isLoading && this._input.loadingWidget) {
            return this._input.loadingWidget;
          } else if (hasError && this._input.errorWidget) {
            return this._input.errorWidget;
          } else if (
            !context ||
            (isEmpty(context[this._input.dataAlias || 'data']) &&
              this._input.emptyWidget)
          ) {
            return this._input.emptyWidget;
          } else {
            return this._input.widget;
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe(activeWidget => this._activeWidget$.next(activeWidget));

    combineLatest([
      this._refresh$,
      this._data$,
      this.inputContext$.pipe(distinctUntilChanged(isEqual)),
    ])
      .pipe(
        switchMap(([, data, context]) => {
          this._loadingStateCounter.reset();
          return this._parser
            .parse(data, {
              context: context,
              log:
                !this._config || !this._config.PRODUCTION
                  ? console.log
                  : undefined,
              loadingStateCounter: this._loadingStateCounter,
            })
            .pipe(
              map((resolvedData: Record<string, any>) => {
                this._loadingStateCounter.reset();
                if (
                  resolvedData &&
                  typeof resolvedData === 'object' &&
                  !Array.isArray(resolvedData) &&
                  typeof resolvedData.actions !== 'undefined'
                ) {
                  const action = resolvedData.actions as WidgetActionModel;
                  this._dispatcher.dispatch(action, resolvedData).toPromise();
                }
                return assign(
                  {},
                  context || {},
                  this._input.dataAlias
                    ? { [this._input.dataAlias || 'data']: resolvedData }
                    : resolvedData
                );
              }),
              catchError(error => {
                this._error$.next(error);
                return throwError(error);
              }),
              retryWhen(() => this._refresh$),
              takeUntil(this._destroy$)
            );
        })
      )
      .subscribe(newContext =>
        this._resolvedContext$.next(newContext as Record<string, any>)
      );

    combineLatest([
      this._activeWidget$,
      this._error$.pipe(
        switchMap(error => {
          if (error) {
            return of(error);
          } else {
            return this._resolvedContext$;
          }
        })
      ),
    ])
      .pipe(
        map(([w, c]) => [[w, c] as WidgetDefinitionTuple]),
        takeUntil(this._destroy$)
      )
      .subscribe(newState => {
        this.__state$.next(newState);
      });

    this._resolvedContext$
      .pipe(takeUntil(this._destroy$))
      .subscribe(newContext => {
        if (this._input.onDataChanged) {
          let actions: WidgetActionModel[];
          if (Array.isArray(this._input.onDataChanged)) {
            actions = this._input.onDataChanged;
          } else {
            actions = [this._input.onDataChanged];
          }
          this._dispatcher
            .dispatch(
              actions.map(it => ({ ...it, sourceWidgetId: this.id })),
              newContext
            )
            .toPromise();
        }
      });
  }

  disconnect() {
    if (this._input.id) {
      this._store.dispatch(
        new DeregisterWidget(this.id, this._input.resetOnDestroy)
      );
    }

    this._destroy$.next();
    this._destroy$.complete();
  }

  getState(context?: Record<string, any>): Observable<WidgetDefinitionTuple[]> {
    this.inputContext$.next(context);
    return this._state$;
  }
}
