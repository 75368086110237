import {
  ConditionalActionModel,
  GlobalActionBase,
  LocalActionModel,
  LocalBatchActionModel,
  RemoteActionModel,
  RemoteWidgetActionError,
  WidgetActionBase,
  WidgetActionModel,
} from '@trackback/widgets';

export function isGlobalAction(obj: WidgetActionBase): obj is GlobalActionBase {
  return obj.type === 'global';
}

export function isConditionalAction(
  obj: WidgetActionModel
): obj is ConditionalActionModel {
  return obj.type === 'conditional';
}

export function isLocalAction(obj: WidgetActionModel): obj is LocalActionModel {
  return obj.type === 'local';
}

export function isLocalBatchAction(
  obj: WidgetActionModel
): obj is LocalBatchActionModel {
  return obj.type === 'local-batch';
}

export function isRemoteError(obj: any): obj is RemoteWidgetActionError {
  return (
    typeof obj === 'object' &&
    (typeof obj.code === 'string' || typeof obj.code === 'number') &&
    (obj.messageTranslationKey === undefined ||
      typeof obj.messageTranslationKey === 'string') &&
    (obj.developerMessage === undefined ||
      typeof obj.developerMessage === 'string')
  );
}

export function isRemoteAction(
  obj: WidgetActionModel
): obj is RemoteActionModel {
  return obj.type === 'remote';
}
