import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Injector,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { AccordionInput, AccordionOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '@app/widgets/base-widget.component';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'tb-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatExpansionModule, NgFor, DynamicWidgetDirective],
})
@Register('Accordion')
export class AccordionComponent
  extends BaseWidgetComponent<AccordionInput, AccordionOutput>
  implements OnInit
{
  @HostBinding('style.paddingTop')
  paddingTop;

  @HostBinding('style.paddingRight')
  paddingRight;

  @HostBinding('style.paddingBottom')
  paddingBottom;

  @HostBinding('style.paddingLeft')
  paddingLeft;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }
}
