import { Inject, Injectable, Optional } from '@angular/core';
import { ParserService } from './parser.service';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { EMPTY, merge, Observable, of } from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  take,
} from 'rxjs/operators';
import { isPrimitiveType } from '@app/utils/is-primitive-type';
import {
  EqualValidatorModel,
  RegexValidatorModel,
  RequiredValidatorModel,
  SizeValidatorModel,
  ValidatorModel,
} from '@trackback/widgets';
import { ValidatorErrorModel } from '@trackback/widgets/build/main/widgets/validators';
import { APP_CONFIG, AppConfigModel } from '@app/models/app-config.model';

@Injectable({
  providedIn: 'root',
})
export class FormFieldValidatorService {
  constructor(
    private readonly parser: ParserService,
    @Optional() @Inject(APP_CONFIG) private readonly config?: AppConfigModel
  ) {}

  createAsyncValidator(validators: ValidatorModel[]): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const contextFactory = control['contextFactory'];
      const context =
        typeof contextFactory === 'function' ? contextFactory() : {};

      return this.parser
        .parseOnce(validators, {
          context: context,
          log:
            !this.config || !this.config.PRODUCTION ? console.log : undefined,
        })
        .pipe(
          mergeMap(parsedValidators => {
            const resultObservables: Observable<ValidationErrors | null>[] = [];
            const validatorArray = parsedValidators as ValidatorModel[];
            for (const validator of validatorArray) {
              resultObservables.push(
                this.getResult(context, control.value, validator).pipe(
                  filter(result => !!result),
                  map(result => ({
                    [validator.name]: result,
                  })),
                  catchError(() => EMPTY)
                )
              );
            }

            // Return combined Result
            if (resultObservables.length) {
              return merge(...resultObservables).pipe(
                take(1),
                defaultIfEmpty(null)
              );
            } else {
              return of(null);
            }
          })
        );
    };
  }

  getResult(
    context: Record<string, any>,
    value: any,
    validator: ValidatorModel
  ): Observable<ValidatorErrorModel | null> {
    switch (validator.type) {
      case 'required':
        return of(this.validateRequired(value, validator));
      case 'size':
        return of(this.validateSize(value, validator));
      case 'regex':
        return this.parser
          .parseOnce((validator as RegexValidatorModel).regex, {
            context,
            log:
              !this.config || !this.config.PRODUCTION ? console.log : undefined,
          })
          .pipe(
            map((resolvedRegex: string) => {
              function validate(input) {
                if (isPrimitiveType(typeof input)) {
                  return input.toString().match(resolvedRegex) != null;
                } else if (Array.isArray(input)) {
                  return input.every(validate);
                } else if (typeof input === 'object') {
                  return Object.values(input).some(validate);
                } else {
                  return false;
                }
              }
              if (!validate(value) || value === null || value === undefined) {
                return createValidationError(validator);
              }
              return null;
            })
          );
      case 'equal':
        return this.parser
          .parseOnce((validator as EqualValidatorModel).value, {
            context,
            log:
              !this.config || !this.config.PRODUCTION ? console.log : undefined,
          })
          .pipe(
            map((resolvedValue: string) => {
              if (value !== resolvedValue) {
                return createValidationError(validator);
              }
              return null;
            })
          );
      default:
        return of(null);
    }
  }

  validateRequired(
    value: any,
    model: RequiredValidatorModel
  ): ValidatorErrorModel | null {
    if (value === null) {
      return createValidationError(model);
    } else if (Array.isArray(value) && value.length === 0) {
      return createValidationError(model);
    } else if (typeof value === 'string' && value.length === 0) {
      return createValidationError(model);
    } else if (typeof value === 'object' && Object.keys(value).length === 0) {
      return createValidationError(model);
    }

    return null;
  }

  validateSize(
    value: any,
    model: SizeValidatorModel
  ): ValidatorErrorModel | null {
    if (model) {
      let actual: any = null;

      if (Array.isArray(value) || typeof value === 'string') {
        actual = value.length;
      } else if (typeof value === 'number') {
        actual = value;
      } else if (typeof value === 'object') {
        actual = Object.keys(value).length;
      }

      if (actual !== null) {
        if (!(model.max === undefined || actual <= model.max)) {
          return this.createSizeValidationError(model, actual);
        }
      }
    }

    return null;
  }

  createSizeValidationError = (model: SizeValidatorModel, v: number) =>
    createValidationError(model, v);
}

export function createValidationError(
  model: ValidatorModel,
  errorContext?: any
): ValidatorErrorModel {
  return {
    ...model,
    errorContext,
  };
}
