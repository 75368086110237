import type { SearchExpression } from '@trackback/widgets';
import { createSimpleNamedExpressionResolver } from '../types';

export const searchResolver =
  createSimpleNamedExpressionResolver<SearchExpression>(
    'search',
    (items, searchTerm) =>
      items.filter(item => {
        const stringItem = JSON.stringify(
          Object.values(item).map(value => String(value).toLowerCase())
        );
        return stringItem.indexOf(String(searchTerm).toLowerCase()) !== -1;
      })
  );
