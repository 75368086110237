import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import {
  SlideToggleFieldInput,
  SlideToggleFieldOutput,
} from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { _MatSlideToggleRequiredValidatorModule } from '@angular/material/slide-toggle';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';

@Register('SlideToggleField')
@Component({
  selector: 'tb-slide-toggle-field',
  templateUrl: './slide-toggle-field.component.html',
  styleUrls: ['./slide-toggle-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacySlideToggleModule,
    _MatSlideToggleRequiredValidatorModule,
    ReactiveFormsModule,
    NgIf,
    MatLegacyFormFieldModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class SlideToggleFieldComponent
  extends BaseFormFieldWidgetComponent<
    SlideToggleFieldInput,
    SlideToggleFieldOutput
  >
  implements OnInit {}
