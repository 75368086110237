import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { ExpansionPanelInput, ExpansionPanelOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { NgIf, NgStyle, NgFor, AsyncPipe } from '@angular/common';
import { DynamicWidgetDirective } from '../../directives/dynamic-widget.directive';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'tb-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatExpansionModule,
    DynamicWidgetDirective,
    NgIf,
    NgStyle,
    NgFor,
    AsyncPipe,
    ParsePipe,
  ],
})
@Register('ExpansionPanel')
export class ExpansionPanelComponent
  extends BaseWidgetComponent<ExpansionPanelInput, ExpansionPanelOutput>
  implements OnInit, OnDestroy
{
  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }
}
