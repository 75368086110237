import { TranslationMap } from '../../models/translation-map.model';
import { Register } from '@app/utils/type-registry';
import { ErrorModel } from '@app/models/error.model';

@Register('SetLanguage')
export class SetLanguage {
  static readonly type = '[Shared.Translation] Set Language';

  constructor(public languageCode: string) {}
}

export class LoadTranslationSuccess {
  static readonly type = '[Shared.Translation] Load Translation Success';

  constructor(
    public languageCode: string,
    public translations: TranslationMap
  ) {}
}

export class LoadTranslationFail {
  static readonly type = '[Shared.Translation] Load Translation Fail';

  constructor(public error: ErrorModel) {}
}
