import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AudioInput, AudioOutput, LocalActionModel } from '@trackback/widgets';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';
import { Register } from '@app/utils/type-registry';

@Register('Audio')
@Component({
  selector: 'tb-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AsyncPipe, ParsePipe],
})
export class AudioComponent
  extends BaseWidgetComponent<AudioInput, AudioOutput>
  implements OnInit, AfterViewInit, OnDestroy
{
  // tslint:disable: variable-name
  protected readonly _audioPlaying$$ = new BehaviorSubject(false);
  protected readonly _playAudio$$ = new BehaviorSubject(false);
  @ViewChild('audioComponent') public audioComponent;

  setPlayAudio(flag: boolean) {
    this._playAudio$$.next(flag);
  }

  handleSetPlayAudioAction(action: LocalActionModel): Observable<any> {
    this.setPlayAudio(action.payload as boolean);
    return of(action.payload);
  }

  handleSeekToAction(action: LocalActionModel): Observable<any> {
    const audio = this.audioComponent.nativeElement as HTMLMediaElement;
    audio.currentTime = action.payload as number;
    return of(null);
  }

  ngAfterViewInit() {
    const audio = this.audioComponent.nativeElement as HTMLMediaElement;

    audio.onplaying = () => {
      if (this.input.playingAction) {
        this.dispatchActionsPromise(this.input.playingAction);
      }
      this._audioPlaying$$.next(true);
    };
    audio.onended = () => {
      if (this.input.endedAction) {
        this.dispatchActionsPromise(this.input.endedAction);
      }
      this._audioPlaying$$.next(false);
    };
    audio.onpause = () => {
      if (this.input.pauseAction) {
        this.dispatchActionsPromise(this.input.pauseAction);
      }
      this._audioPlaying$$.next(false);
    };
    audio.ontimeupdate = () => {
      this.updateOutput({
        currentTime: audio.currentTime,
      } as Partial<AudioOutput>);
      if (this.input.timeUpdateAction) {
        this.dispatchActionsPromise(this.input.timeUpdateAction);
      }
    };

    this.parse(this.input.url).subscribe(url => {
      (audio.children[0] as HTMLMediaElement).src = String(url);
      audio.load();
    });

    this._playAudio$$.pipe(takeUntil(this.destroyed$)).subscribe(play => {
      if (play) {
        audio.play();
      } else if (!play) {
        audio.pause();
      }
    });
  }

  async ngOnInit() {
    await super.ngOnInit();

    // default  the audio preload  configuration option to  metadata as per
    // the mozilla documentation if it has not been defined by the consumer
    //
    // default: metadata
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/audio
    if (this.input.preload == null) {
      this.input.preload = 'metadata';
    }

    this.updateOutput({
      currentTime: 0,
    } as Partial<AudioOutput>);

    this._audioPlaying$$
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(playing => {
        this.updateOutput({
          playing,
        } as Partial<AudioOutput>);
      });
  }

  ngOnDestroy(): void {
    if (this._audioPlaying$$ && !this._audioPlaying$$.closed) {
      this._audioPlaying$$.unsubscribe();
    }
    if (this._playAudio$$ && !this._playAudio$$.closed) {
      this._playAudio$$.unsubscribe();
    }
    super.ngOnDestroy();
  }
}
