import { IfInputModel } from '@trackback/widgets';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetDefinitionTuple } from '@app/models/widget-input.model';
import { ParserService } from '@app/services/parser.service';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from './index';
import { WidgetResolver } from './widget-resolver';
import { Register } from '@app/utils/type-registry';
import { APP_CONFIG } from '@app/models/app-config.model';

@Register('if', STRUCTURAL_WIDGET_TYPE_GROUP)
export class ConditionalWidget extends WidgetResolver<IfInputModel<any, any>> {
  private readonly _parser = this._injector.get(ParserService);
  private readonly _config = this._injector.get(APP_CONFIG, null);

  getState(context?: Record<string, any>): Observable<WidgetDefinitionTuple[]> {
    return this._parser
      .parse(this._input.condition, {
        context: context,
        log:
          !this._config || !this._config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        map(result => {
          const widget = result
            ? this._input.thenWidget
            : this._input.elseWidget;
          const newContext = this._input.as
            ? Object.assign({}, context, { [this._input.as]: result })
            : context;
          return [[widget, newContext] as WidgetDefinitionTuple];
        })
      );
  }
}
