import { CoalesceExpression, isExpression } from '@trackback/widgets';
import { createNamedExpressionResolver } from '../types';
import { DataChangeCallback, ParseOptions, Parser } from '../parser';

export const coalesceResolver =
  createNamedExpressionResolver<CoalesceExpression>(
    'coalesce',
    (parser, options, onDataChange, [values]) => {
      if (isExpression(values)) {
        return parser.parseWithCallback(
          values,
          {
            error: onDataChange.error,
            next: resolvedValues =>
              onDataChange.next(resolvedValues.find(Boolean)),
          },
          options
        );
      } else {
        return recurse(parser, options, onDataChange, values);
      }
    }
  );

const recurse = (
  parser: Parser,
  options: ParseOptions,
  onDataChange: DataChangeCallback<unknown>,
  values: Array<unknown>,
  index = 0
) =>
  parser.parseWithCallback(
    values[index],
    {
      error: onDataChange.error,
      next: resolvedValue => {
        if (resolvedValue) {
          return onDataChange.next(resolvedValue);
        } else if (index >= values.length - 1) {
          return onDataChange.next(undefined);
        } else {
          return recurse(parser, options, onDataChange, values, index + 1);
        }
      },
    },
    options
  );
