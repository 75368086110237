import { EMPTY, forkJoin, Observable } from 'rxjs';

/**
 * The native RxJS forkJoin method will break if an empty array is passed.
 * This method handles this corner-case by returning an EMPTY observable in that case.
 */
export default function safeForkJoin<T>(observables: Observable<T>[]) {
  if (observables.length) {
    return forkJoin(observables);
  }
  return EMPTY;
}
