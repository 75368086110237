import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class LoadingStateCounter {
  private readonly _counter$ = new BehaviorSubject(0);
  private readonly _loading$ = this._counter$.pipe(map(counter => counter > 0));

  get count(): number {
    return this._counter$.getValue();
  }

  get isLoading(): boolean {
    return this._counter$.getValue() > 0;
  }

  get counter$(): Observable<number> {
    return this._counter$;
  }

  get isLoading$(): Observable<boolean> {
    return this._loading$.pipe(distinctUntilChanged());
  }

  public increment(): Observable<boolean> {
    this._counter$.next(this._counter$.getValue() + 1);
    return this.isLoading$;
  }

  public decrement(): Observable<boolean> {
    this._counter$.next(Math.max(0, this._counter$.getValue() - 1));
    return this.isLoading$;
  }

  public reset(): void {
    this._counter$.next(0);
  }
}
