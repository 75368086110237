import { enGB, es, de, it, fr, nl, pt, fi, da, ro, hu, el } from 'date-fns/locale';
export default {
  'en-GB': enGB,
  en: enGB,
  'es-ES': es,
  es,
  'fr-FR': fr,
  'fr-BE': fr,
  fr,
  'nl-BE': nl,
  nl,
  'it-IT': it,
  it,
  de,
  'pt-PT': pt,
  pt,
  'el-GR': el,
  el,
  'fi-FI': fi,
  fi,
  'da-DK': da,
  da,
  'hu-HU': hu,
  hu,
  'ro-RO': ro,
  ro
};
