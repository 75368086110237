/**
 * Buttons provide a surface which dispatches an action on click.
 *
 * Either a text and/or an icon should be provided.
 *
 * This implementation follows the [Official Specification](https://material.io/design/components/buttons.html) and
 * is based on the [Angular Material Implementation](https://material.angular.io/components/button/examples).
 *
 * @module widgets/button
 * @example A simple icon button which opens a snackbar
 * ```
 * {
 *   type: 'Button',
 *   style: 'icon',
 *   icon: 'create',
 *   clickAction: {
 *             type: 'global',
 *             name: 'OpenSimpleSnackbar',
 *             payload: {
 *               text: 'Button clicked'
 *             }
 *           }
 * }
 * ```
 */

/** Required comment to display module description, wont be included in the documentation */

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Register } from '@app/utils/type-registry';
import {
  ButtonInput,
  ButtonOutput,
  LocalActionModel,
  MatColorDefinitionModel,
} from '@trackback/widgets';
import { of, Subject, Subscription } from 'rxjs';
import { finalize, retryWhen, switchMap, tap } from 'rxjs/operators';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  AsyncPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';

/**
 * @ignore
 */
@Register('Button')
@Component({
  selector: 'tb-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    MatLegacyButtonModule,
    NgTemplateOutlet,
    MatLegacyTooltipModule,
    MatLegacyMenuModule,
    NgFor,
    DynamicWidgetDirective,
    NgIf,
    MatLegacyProgressSpinnerModule,
    MatIconModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class ButtonComponent
  extends BaseWidgetComponent<ButtonInput, ButtonOutput>
  implements OnInit, OnDestroy
{
  private _subscriptions: Subscription[] = [];
  private _clicks$ = new Subject<void>();
  color: ThemePalette | null;
  @ViewChild('menuTrigger') MenuTrigger;

  protected setColorActive(
    _type: 'foreground' | 'background',
    color: MatColorDefinitionModel
  ) {
    this.color = typeof color === 'object' ? color.palette : color;
    this._cd.markForCheck();
  }

  onClick(event: MouseEvent) {
    if (this.input.clickAction) {
      event.stopPropagation();
      this._clicks$.next();
    }
  }

  async ngOnInit() {
    await super.ngOnInit();
    this._subscriptions.push(
      this._clicks$
        .pipe(
          switchMap(() =>
            of(null).pipe(
              tap(() => this.updateOutput({ actionPending: true }, false)),
              switchMap(() =>
                this.dispatchActions(this.input.clickAction).pipe(
                  finalize(() =>
                    this.updateOutput({ actionPending: false }, false)
                  )
                )
              ),
              retryWhen(() => this._clicks$)
            )
          )
        )
        .subscribe(() => {})
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
    super.ngOnDestroy();
  }

  handleOpenMenuAction(action: LocalActionModel) {
    const payload: boolean = action.payload as boolean;
    if (payload && this.input.menuWidgets) {
      this.MenuTrigger.openMenu();
    }
    return of(null);
  }
}
