/**
 *
 * @module widgets/base
 * @preferred
 */
/** Required comment to display module description, wont be included in the documentation */
import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Sanitizer,
  SimpleChanges,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { Select, Store } from '@ngxs/store';
import {
  BaseWidgetInputModel,
  DimensionGapsModel,
  Expression,
  LocalActionModel,
  MatColorDefinitionModel,
  Resolved,
  WidgetActionModel,
} from '@trackback/widgets';
import * as FileSaver from 'file-saver';
import { remove, uniq } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import {
  first,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  ACTION_DISPATCHER,
  ActionDispatcherService,
  LocalActionDispatcher,
} from '../models/action-dispatcher.model';
import { OutputService } from '../services/output.service';
import { ParserService } from '../services/parser.service';
import { RegistrationService } from '../services/registration.service';
import * as WidgetActions from '../state/widgets/widgets.actions';
import { ensureArray } from '../utils/ensure-array';
import { UpdateWidgetOutput } from './../state/widgets/widgets.actions';
import { APP_CONFIG, AppConfigModel } from '@app/models/app-config.model';
import { LayoutState } from '@app/state/layout/layout.state';
import {
  AppError,
  DEFAULT_ERROR_TRANSLATION_KEYS,
} from '@app/models/error.model';
import { ParseOptions } from '@app/expressions/parser';

/**
 * @ignore
 *
 * @param T Input type
 * @param C Output type
 */
@Directive()
export abstract class BaseWidgetComponent<
    I extends BaseWidgetInputModel,
    O extends Record<string, any>,
  >
  implements OnInit, OnDestroy, OnChanges
{
  @HostBinding('style.alignSelf')
  get alignSelf() {
    if (this.input.layout && this.input.layout.alignSelf) {
      return this.input.layout.alignSelf;
    } else {
      return null;
    }
  }

  @HostBinding('style.flexGrow')
  get growWeight() {
    if (
      this.input.layout &&
      (this.input.layout.growWeight || this.input.layout.growWeight === 0)
    ) {
      return this.input.layout.growWeight;
    } else {
      return null;
    }
  }

  @HostBinding('style.flexShrink')
  get shrinkWeight() {
    if (
      this.input.layout &&
      (this.input.layout.shrinkWeight || this.input.layout.shrinkWeight === 0)
    ) {
      return this.input.layout.shrinkWeight;
    } else {
      return null;
    }
  }
  get maxWidth() {
    let baseMaxWidth:
      | string
      | number
      | undefined
      | Expression<any, string>
      | Expression<any, number> = this.getBaseMaxWidth();
    baseMaxWidth = this.maximumWidth;

    const leftMargin: string | number | undefined = this.marginLeft;
    const rightMargin: string | number | undefined = this.marginRight;

    if (leftMargin && rightMargin) {
      return `calc(${baseMaxWidth} - ${leftMargin} - ${rightMargin})`;
    } else if (leftMargin) {
      return `calc(${baseMaxWidth} - ${leftMargin})`;
    } else if (rightMargin) {
      return `calc(${baseMaxWidth} - ${rightMargin})`;
    } else if (baseMaxWidth) {
      return baseMaxWidth;
    } else {
      return 'auto';
    }
  }

  @HostBinding('class')
  get classes() {
    return this._classes.join(' ');
  }

  public get destroyed$() {
    return this._destroy$.pipe(first());
  }

  constructor(@Optional() injector?: Injector) {
    if (injector) {
      this._cd = injector.get(ChangeDetectorRef);
      this._parser = injector.get(ParserService);
      this._store = injector.get(Store);
      this._sanitizer = injector.get(Sanitizer);
      this._registrationService = injector.get(RegistrationService);
      this._outputService = injector.get(OutputService);
      this._dispatcher = injector.get(ACTION_DISPATCHER);
      this._config = injector.get(APP_CONFIG, null);
    }
  }
  @HostBinding('id')
  id = '';

  paddingTop?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingLeft?: string;

  tooltipText?: string;
  tooltipPosition?: TooltipPosition | string;

  @HostBinding('style.marginTop')
  marginTop?: string;

  @HostBinding('style.marginRight')
  marginRight?: string;

  @HostBinding('style.marginBottom')
  marginBottom?: string;

  @HostBinding('style.marginLeft')
  marginLeft?: string;

  @HostBinding('style.flexBasis')
  flexBasis?: string | number = null;

  @HostBinding('style.minWidth')
  minWidth?:
    | string
    | number
    | undefined
    | Expression<any, string>
    | Expression<any, number> = null;

  @HostBinding('style.maxWidth')
  maximumWidth?:
    | string
    | number
    | undefined
    | Expression<any, string>
    | Expression<any, number> = null;

  @HostBinding('style.opacity')
  opacity?: string;

  @Input()
  input: I;

  @Input()
  context: Record<string, any>;

  private _destroy$ = new Subject<void>();

  public _cd: ChangeDetectorRef;
  public _parser: ParserService;
  public _store: Store;
  public _dispatcher: ActionDispatcherService;
  public _registrationService: RegistrationService;
  public _outputService: OutputService;
  public _sanitizer: Sanitizer;
  public readonly _config: AppConfigModel;
  private _classes: string[] = [];
  protected context$ = new BehaviorSubject<Record<string, any> | undefined>(
    undefined
  );
  protected input$ = new BehaviorSubject<I | undefined>(undefined);

  @Select(LayoutState.isSize('large'))
  isLarge$: Observable<boolean>;

  public readonly mergeContexts = (
    contextA: Record<string, any>,
    contextB: Record<string, any>
  ) => ({ ...contextA, ...contextB });

  wrapContext = (
    context: Record<string, any>,
    alias?: string
  ): Record<string, any> => (alias ? { [alias]: context } : context);

  protected addStyleClasses(...classes: string[]) {
    classes.forEach(cls => this._classes.push(cls));
    this._classes = uniq(this._classes);
    this._cd.markForCheck();
  }

  protected removeStyleClasses(...classes: string[]) {
    remove(this._classes, cls => classes.includes(cls));
    this._classes = uniq(this._classes);
    this._cd.markForCheck();
  }

  protected removeStyleClassesByRegex(regex: RegExp | string) {
    remove(this._classes, cls => cls.match(regex));
    this._classes = uniq(this._classes);
    this._cd.markForCheck();
  }

  /**
   * Returns the static default max width of this widget type.
   *
   * As implementation might vary between widget types, this method may be overridden.
   *
   * @default 100%
   */
  protected getBaseMaxWidth(): string | number | undefined {
    return '100%';
  }

  /**
   * Method responsible for defining foreground & background color of the widget.
   *
   * As implementation might vary between widget types, this method may be overridden.
   *
   * @param type Which part of the widget to change, foreground or background
   * @param color
   * @param flag Whether to add or remove the color
   */
  protected setColorActive(
    type: 'foreground' | 'background',
    color: MatColorDefinitionModel | ThemePalette | null,
    flag: boolean
  ) {
    let cls: string | null = null;
    const prefix = type === 'foreground' ? 'color' : 'background-color';
    if (color && typeof color === 'object') {
      if (color.palette) {
        cls = `${prefix}-${color.palette}`;
        if (color.hue) {
          cls += `-hue-${color.hue}`;
        }
        if (color.useContrast) {
          cls += `-contrast`;
        }
        if (color.opacity) {
          if (color.opacity % 5 === 0) {
            cls += `-opacity-${color.opacity}`;
          } else {
            console.warn(
              `Text color opacity has to be a number that is a multiple of 5, found`,
              color.opacity
            );
          }
        }
      } else if (color.opacity) {
        if (color.opacity % 5 === 0) {
          cls = `${type}-opacity-${color.opacity}`;
        } else {
          console.warn(
            `Text color opacity has to be a number that is a multiple of 5, found`,
            color.opacity
          );
        }
      }
    } else if (typeof color === 'string') {
      cls = `${prefix}-${color}`;
      if (flag) {
        this.addStyleClasses(cls);
      } else {
        this.removeStyleClasses(cls);
      }
    }
    if (cls) {
      if (flag) {
        this.addStyleClasses(cls);
      } else {
        this.removeStyleClasses(cls);
      }
    }
  }

  // Optional Local Action Handler to be provided by implementing class

  readonly _handle: LocalActionDispatcher = (
    action: LocalActionModel
  ): Observable<any> => {
    const handlerName = `handle${action.name}Action`;
    if (handlerName in this) {
      return (this[handlerName] as LocalActionDispatcher)(action);
    } else {
      return throwError(
        new AppError(
          'widgets/invalid-action',
          DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR,
          `Widget (${this.id}) does not have handler for action (${action.name})`
        )
      );
    }
  };

  // Utility Methods for implementing class

  public readonly register = (initialOutput?: Partial<O>) => {
    if (!this.id) {
      return;
    }
    if (typeof this.input === 'object') {
      this._registrationService.register({
        id: this.id,
        alias: this.input.alias,
        dispatcher: this._handle,
        initialOutput,
      });
    } else {
      throw new AppError(
        `widgets/missing-input`,
        `application_error`,
        `Must not register widget without input`
      );
    }
  };

  public readonly deregister = (resetOutput = false) =>
    this.id &&
    this._registrationService.deregister({ id: this.id, resetOutput });

  public readonly parse = <T>(
    value: T,
    options: Omit<ParseOptions, 'context'> = {}
  ): Observable<Resolved<T>> =>
    this.context$.pipe(
      switchMap(context =>
        this._parser
          .parse(value, {
            ...options,
            context: context,
          })
          .pipe(takeUntil(this.destroyed$))
      )
    );

  public readonly updateOutput = (newOutput: Partial<O>, batch = true) => {
    if (this.id) {
      if (batch) {
        this._outputService.updateOutput({
          widgetId: this.id,
          output: newOutput,
        });
      } else {
        return this._store
          .dispatch(new UpdateWidgetOutput(this.id, newOutput))
          .toPromise();
      }
    } else if (!this._config || !this._config.PRODUCTION) {
      console.warn(
        'Cannot update output',
        newOutput,
        'for widget',
        this.input,
        'without id'
      );
    }
  };

  public readonly resetOutput = (newOutput: O) => {
    if (this.id) {
      return this._store.dispatch(
        new WidgetActions.ResetWidgetOutput(this.id, newOutput)
      );
    } else if (!this._config || !this._config.PRODUCTION) {
      console.warn(
        'Cannot reset output',
        newOutput,
        'for widget',
        this.input,
        'without id'
      );
    }
  };

  public attachSourceWidgetIds(
    actions: WidgetActionModel | WidgetActionModel[]
  ): WidgetActionModel[] {
    return ensureArray(actions).map(action => ({
      ...action,
      sourceWidgetId: this.id,
    }));
  }

  public readonly dispatchActions = (
    action: WidgetActionModel | WidgetActionModel[]
  ): Observable<any> => {
    return this._dispatcher.dispatch(
      this.attachSourceWidgetIds(action),
      this.context
    );
  };

  public readonly dispatchActionsPromise = (
    action: WidgetActionModel | WidgetActionModel[],
    context = {}
  ): Promise<any> => {
    const dispatchedContext = {
      ...this.context,
      ...context,
    };
    return this._dispatcher
      .dispatch(this.attachSourceWidgetIds(action), dispatchedContext)
      .pipe(takeUntil(this.destroyed$))
      .toPromise();
  };

  public destroy() {
    this._destroy$.next();
    this._destroy$.complete();
    if (this.input.afterDeregisterAction) {
      this.dispatchActions(this.input.afterDeregisterAction).toPromise();
    }
  }

  protected parseId(): Promise<void> {
    return this._parser
      .parseOnce(this.input.id, {
        context: this.context,
        log:
          !this._config || !this._config.PRODUCTION ? console.log : undefined,
      })
      .toPromise()
      .then(widgetId => {
        if (widgetId) {
          this.id = widgetId;
          this.context = Object.assign({}, this.context || {}, {
            _id: this.id,
          });
          this.context$.next(this.context);
        }
      });
  }

  protected init() {
    this.addStyleClasses('tb-widget');
    if (this.input.outlined) {
      this.addStyleClasses('outlined');
    }
    if (this.input.hidden) {
      this.parse(this.input.hidden).subscribe(hidden => {
        if (hidden) {
          this.addStyleClasses('hidden');
        } else {
          this.removeStyleClasses('hidden');
        }
      });
    }

    if (
      this.input.width &&
      (this.input.width.max || this.input.width.max === 0)
    ) {
      this.parse(this.input.width.max).subscribe(max => {
        if (max === null || max === undefined) {
          this.maximumWidth = undefined;
        } else if (typeof max === 'number' || typeof max === 'string') {
          this.maximumWidth = max;
        }
        this._cd.markForCheck();
      });
    }
    if (
      this.input.width &&
      (this.input.width.min || this.input.width.min === 0)
    ) {
      this.parse(this.input.width.min).subscribe(min => {
        if (min === null || min === undefined) {
          this.minWidth = undefined;
        } else if (typeof min === 'number' || typeof min === 'string') {
          this.minWidth = min;
        }
        this._cd.markForCheck();
      });
    }

    if (
      this.input.layout &&
      (this.input.layout.size || this.input.layout.size === 0)
    ) {
      this.parse(this.input.layout.size).subscribe(size => {
        if (size === null || size === undefined) {
          this.flexBasis = null;
        } else if (typeof size === 'string' || size === 0) {
          this.flexBasis = size;
        }
        this._cd.markForCheck();
      });
    }

    if (this.input.padding) {
      this.parse(this.input.padding).subscribe(padding => {
        if (padding === null || padding === undefined) {
          this.paddingTop = undefined;
          this.paddingLeft = undefined;
          this.paddingBottom = undefined;
          this.paddingRight = undefined;
        } else if (typeof padding === 'object') {
          const paddingObject = padding as DimensionGapsModel;
          this.paddingTop =
            typeof paddingObject.top === 'number'
              ? `${paddingObject.top}px`
              : String(paddingObject.top);
          this.paddingBottom =
            typeof paddingObject.bottom === 'number'
              ? `${paddingObject.bottom}px`
              : String(paddingObject.bottom);
          this.paddingRight =
            typeof paddingObject.right === 'number'
              ? `${paddingObject.right}px`
              : String(paddingObject.right);
          this.paddingLeft =
            typeof paddingObject.left === 'number'
              ? `${paddingObject.left}px`
              : String(paddingObject.left);
        } else if (typeof padding === 'number') {
          this.paddingTop = `${padding}px`;
          this.paddingBottom = `${padding}px`;
          this.paddingRight = `${padding}px`;
          this.paddingLeft = `${padding}px`;
        } else if (typeof padding === 'string') {
          this.paddingTop = padding;
          this.paddingBottom = padding;
          this.paddingRight = padding;
          this.paddingLeft = padding;
        }
        this._cd.markForCheck();
      });
    }
    if (this.input.margin) {
      this.parse(this.input.margin).subscribe(margin => {
        if (margin === null || margin === undefined) {
          this.marginTop = undefined;
          this.marginLeft = undefined;
          this.marginBottom = undefined;
          this.marginRight = undefined;
        } else if (typeof margin === 'object') {
          const marginObject = margin as DimensionGapsModel;
          this.marginTop =
            typeof marginObject.top === 'number'
              ? `${marginObject.top}px`
              : String(marginObject.top);
          this.marginBottom =
            typeof marginObject.bottom === 'number'
              ? `${marginObject.bottom}px`
              : String(marginObject.bottom);
          this.marginRight =
            typeof marginObject.right === 'number'
              ? `${marginObject.right}px`
              : String(marginObject.right);
          this.marginLeft =
            typeof marginObject.left === 'number'
              ? `${marginObject.left}px`
              : String(marginObject.left);
        } else if (typeof margin === 'number') {
          this.marginTop = `${margin}px`;
          this.marginBottom = `${margin}px`;
          this.marginRight = `${margin}px`;
          this.marginLeft = `${margin}px`;
        } else if (typeof margin === 'string') {
          this.marginTop = margin;
          this.marginBottom = margin;
          this.marginRight = margin;
          this.marginLeft = margin;
        }
        this._cd.markForCheck();
      });
    }
    if (this.input.elevation) {
      this.parse(this.input.elevation)
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]) => {
          if (prev) {
            this.removeStyleClasses(`mat-elevation-z${prev}`);
          }
          if (next) {
            this.addStyleClasses(`mat-elevation-z${next}`);
          }
        });
    }
    if (this.input.color) {
      this.parse(this.input.color)
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]) => {
          if (prev) {
            this.setColorActive('foreground', prev, false);
          }
          if (next) {
            this.setColorActive('foreground', next, true);
          }
        });
    }
    if (this.input.backgroundColor) {
      this.parse(this.input.backgroundColor)
        .pipe(startWith(null), pairwise())
        .subscribe(
          ([prev, next]: [
            MatColorDefinitionModel,
            MatColorDefinitionModel,
          ]) => {
            if (prev) {
              this.setColorActive('background', prev, false);
            }
            if (next) {
              this.setColorActive('background', next, true);
            }
          }
        );
    }
    if (this.input.tooltip) {
      this.parse(this.input.tooltip)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(tooltip => {
          if (tooltip && typeof tooltip === 'object') {
            const tooltipObject = tooltip;
            this.tooltipText = String(tooltipObject.text);
            this.tooltipPosition = String(tooltipObject.position);
          }
          this._cd.markForCheck();
        });
    }
    if (this.input.styleClasses) {
      if (Array.isArray(this.input.styleClasses)) {
        this.addStyleClasses(...this.input.styleClasses);
      } else {
        this.addStyleClasses(this.input.styleClasses);
      }
    }
    if (this.input.opacity) {
      this.parse(this.input.opacity)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((opacity: any) => {
          if (opacity) {
            this.opacity = `${opacity * 100}%`;
          } else {
            this.opacity = undefined;
          }
          this._cd.markForCheck();
        });
    }

    if (this.input.afterRegisterAction) {
      this.dispatchActionsPromise(this.input.afterRegisterAction);
    }
  }

  handleDownloadAction(action: LocalActionModel) {
    const payload: any = action.payload;
    return this._parser
      .parseOnce(action.widgetId, {
        context: this.context,
        log:
          !this._config || !this._config.PRODUCTION ? console.log : undefined,
      })
      .pipe(
        switchMap(parsedWidgetId => {
          const DOM: any = document.getElementById(String(parsedWidgetId));
          return from(
            import('html2canvas').then(html2canvas => html2canvas.default(DOM))
          );
        }),
        switchMap(canvas => {
          if (payload.format === 'PDF') {
            return from(this.saveAsPDF(canvas, payload.fileName));
          } else if (payload.format === 'JPEG' || payload.format === 'PNG') {
            return from(this.saveAsImage(canvas, payload));
          }
        })
      );
  }

  private async saveAsImage(canvas: any, payload) {
    const contentDataURL = canvas.toDataURL(
      `image/${payload.format.toLowerCase()}`
    );
    const fileName = payload.fileName
      ? `${payload.fileName}.${payload.format}`
      : `${new Date().getTime()}.${payload.format}`;
    if (canvas.msToBlob) {
      // for IE
      const blob = canvas.msToBlob();
      FileSaver.saveAs(blob, fileName);
    } else {
      const anchor = document.createElement('a');
      anchor.href = contentDataURL;
      anchor.download = fileName;
      anchor.click();
    }
  }

  private async saveAsPDF(canvas: any, fileName) {
    const jsPDF = (await import('jspdf')).jsPDF;
    const contentDataURL = canvas.toDataURL('image/png');
    // Few necessary setting options
    const imgHeight = (canvas.height * 208) / canvas.width;
    const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    fileName = fileName ? `${fileName}.pdf` : `${new Date().getTime()}.pdf`;
    pdf.addImage(contentDataURL, 'PNG', 0, 0, 208, imgHeight);
    pdf.save(fileName); // Generated PDF
  }

  // Lifecycle Event Handlers

  async ngOnInit() {
    await this.parseId();
    this.register();
    this.init();
  }

  ngOnDestroy(): void {
    this.deregister(this.input.resetOnDestroy);
    this.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('input' in changes) {
      if (
        !(this.constructor['registeredAs'] as string | string[]).includes(
          this.input.type
        )
      ) {
        throw new AppError(
          `widgets/type-mismatch`,
          DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR,
          `Input type (${this.input.type}) does not match component type (${this.constructor['registeredAs']})`
        );
      }
      this.input$.next(changes['input'].currentValue);
    }
    if ('context' in changes) {
      let newContext = changes.context.currentValue;
      if (this.id) {
        newContext = Object.assign(newContext, { _id: this.id });
      }
      this.context = newContext;
      this.context$.next(newContext);
    }
  }
}
