<mat-form-field>
  <mat-label *ngIf="input.label | parse: context | async as label">{{
    label
  }}</mat-label>
  <textarea
    matInput
    [formControl]="_formControl"
    [placeholder]="input.placeholder | parse: context | async"
    [required]="input.required"
    (blur)="onBlur($event)"
    rows="10"
  ></textarea>
  <mat-error *ngIf="firstFormControlError as error">
    {{ error.errorMessage | parse: context | async }}
  </mat-error>
</mat-form-field>
