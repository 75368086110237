import type { FormatDateExpression } from '@trackback/widgets';
import { createSimpleNamedObservableExpressionResolver } from '../types';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import locales from '../../models/date-fns-locales';

// See https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md for problem with mismatching tokens in MomentJS
export const createFormatDateResolver = (currentLocale$: Observable<string>) =>
  createSimpleNamedObservableExpressionResolver<FormatDateExpression>(
    'formatDate',
    (utcDate, formatString) =>
      currentLocale$.pipe(
        distinctUntilChanged(),
        map(locale =>
          format(
            utcToZonedTime(
              typeof utcDate === 'string'
                ? parseISO(utcDate)
                : new Date(utcDate),
              'UTC'
            ),
            formatString.replace(/D/g, 'd').replace(/Y/g, 'y'),
            {
              locale: locales[locale],
              timeZone: 'UTC',
            }
          )
        )
      )
  );
