import { Injector } from '@angular/core';
import { WidgetInputModel } from '@trackback/widgets';
import { Observable, of } from 'rxjs';
import { WidgetDefinitionTuple } from '@app/models/widget-input.model';

export class WidgetResolver<T extends WidgetInputModel> {
  constructor(
    protected readonly _input: T,
    protected readonly _injector: Injector
  ) {}

  /**
   * Method called on instantiation to initialise the widget.
   * The default implementation does not perform any actions.
   */
  public connect() {
    // Do nothing
  }

  /**
   * Acts as a class destructor to perform cleanup.
   * The default implementation does not perform any actions.
   */
  public disconnect() {
    // Do nothing
  }

  public getState(
    context?: Record<string, any>
  ): Observable<WidgetDefinitionTuple[]> {
    return of([[this._input, context] as WidgetDefinitionTuple]);
  }
}
