/**
 * Progress spinners display loading state.
 *
 * This implementation follows the [Official Specification](https://material.io/design/components/progress-spinner.html) and
 * is based on the [Angular Material Implementation](https://material.angular.io/components/progress-spinner/examples).
 *
 * @module widgets/progress-spinner
 */

/** Required comment to display module description, wont be included in the documentation */

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Register } from '@app/utils/type-registry';
import {
  MatColorDefinitionModel,
  ProgressSpinnerInput,
  ProgressSpinnerOutput,
} from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';

/**
 * @ignore
 */
@Register('ProgressSpinner')
@Component({
  selector: 'tb-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatLegacyProgressSpinnerModule,
    MatLegacyTooltipModule,
    AsyncPipe,
    ParsePipe,
  ],
})
export class ProgressSpinnerComponent
  extends BaseWidgetComponent<ProgressSpinnerInput, ProgressSpinnerOutput>
  implements OnInit, OnDestroy
{
  color: ThemePalette | null;

  protected setColorActive(
    _type: 'foreground' | 'background',
    color: MatColorDefinitionModel
  ) {
    this.color = typeof color === 'object' ? color.palette : color;
    this._cd.markForCheck();
  }

  async ngOnInit() {
    // Skip Registration
    this.init();
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }
}
