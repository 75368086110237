<mat-form-field>
  <mat-label *ngIf="input.label | parse: context | async as label">{{
    label
  }}</mat-label>
  <input
    type="text"
    matInput
    [matDatepicker]="picker"
    [readonly]="input.disabled | parse: context | async"
    [formControl]="_formControl"
    [placeholder]="input.placeholder | parse: context | async"
    [required]="input.required"
    [min]="_minDate"
    [max]="_maxDate"
    (blur)="onBlur($event)"
    (click)="picker.open()"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    [touchUi]="(isLarge$ | async) !== true"
    #picker
  ></mat-datepicker>
  <mat-error *ngIf="firstFormControlError as error">
    {{ error.errorMessage | parse: context | async }}
  </mat-error>
</mat-form-field>
