/**
 * component  are based on https://www.npmjs.com/package/@angular-material-components/file-input
 */
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { FileUploadFieldInput, FormFieldOutputModel } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
  selector: 'tb-file-upload-field',
  templateUrl: './file-upload-field.component.html',
  styleUrls: ['./file-upload-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyFormFieldModule,
    NgxMatFileInputModule,
    ReactiveFormsModule,
    MatLegacyTooltipModule,
    AsyncPipe,
    ParsePipe,
  ],
})
@Register('FileUploadField')
export class FileUploadFieldComponent
  extends BaseFormFieldWidgetComponent<
    FileUploadFieldInput,
    FormFieldOutputModel
  >
  implements OnInit
{
  onChange(event) {
    // checking the file type
    const accepts = event.target.accept.split(',');
    let file = this._formControl.value;
    file = Array.isArray(file) ? file : [file];
    for (const f of file) {
      const fileExtension = f.name.split('.').pop();
      const valid = accepts.includes(`.${fileExtension}`);
      if (!valid) {
        this._formControl.setErrors({ unmatchedType: true });
        break;
      }
    }
  }

  handleGetFileAction() {
    return [this._formControl.value];
  }
}
