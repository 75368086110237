import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Register } from '@app/utils/type-registry';
import {
  InsertRemoveTriggerInput,
  InsertRemoveTriggerOutput,
} from '@trackback/widgets';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { BaseWidgetComponent } from '@app/widgets/base-widget.component';

@Register(['InsertRemoveTrigger', 'AnimateInsertRemove'])
@Component({
  selector: 'tb-insert-remove-animate',
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('1s', style({ opacity: 0 }))]),
    ]),
  ],
  template: `
    <div @myInsertRemoveTrigger *ngIf="input.isShown | parse: context | async">
      <ng-container
        *ngFor="let widget of input.widgets"
        [dynamicWidget]="widget"
        [context]="context"
      ></ng-container>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, ParsePipe, DynamicWidgetDirective],
})
export class InsertRemoveTriggerComponent
  extends BaseWidgetComponent<
    InsertRemoveTriggerInput,
    InsertRemoveTriggerOutput
  >
  implements OnInit
{
  @HostBinding('style.paddingTop')
  paddingTop?: string;
  @HostBinding('style.paddingRight')
  paddingRight?: string;
  @HostBinding('style.paddingBottom')
  paddingBottom?: string;
  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  public isShown: boolean;

  async ngOnInit() {
    await super.ngOnInit();

    this.parse(this.input.isShown).subscribe((parsedExpression: boolean) => {
      this.isShown = parsedExpression || true;
    });
  }
}
