import { Observable } from 'rxjs';
import type { TranslationsExpression } from '@trackback/widgets';
import { createNoArgsObservableNamedExpressionResolver } from '../types';

export const createTranslationResolver = (
  translations$: Observable<Record<string, string>>
) =>
  createNoArgsObservableNamedExpressionResolver<TranslationsExpression>(
    'translations',
    translations$
  );
