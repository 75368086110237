import { find } from 'lodash-es';
import type { IncludesExpression } from '@trackback/widgets';
import { createSimpleNamedExpressionResolver } from '../types';

export const includesResolver =
  createSimpleNamedExpressionResolver<IncludesExpression>(
    'includes',
    (haystack, needle) => {
      if (Array.isArray(haystack)) {
        if (['string', 'number'].includes(typeof needle)) {
          return !!(<(typeof needle)[]>haystack).find(hay => hay === needle);
        } else {
          return !!find(haystack, needle);
        }
      } else if (haystack !== undefined) {
        return haystack.indexOf(String(needle)) !== -1;
      } else {
        return false;
      }
    }
  );
