<mat-form-field>
  <ngx-mat-file-input
    [formControl]="_formControl"
    (change)="onChange($event)"
    [multiple]="input.multiple | parse: context | async"
    [accept]="input.accept"
    [color]="input.color"
    [placeholder]="input.placeholder | parse: context | async"
    [required]="input.required"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
  >
  </ngx-mat-file-input>
</mat-form-field>
