import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { TextBoxInput, TextBoxOutput } from '@trackback/widgets';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { ParsePipe } from '../../pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

@Register('TextBox')
@Component({
  selector: 'tb-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatLegacyInputModule, ReactiveFormsModule, AsyncPipe, ParsePipe],
})
export class TextBoxComponent extends BaseFormFieldWidgetComponent<
  TextBoxInput,
  TextBoxOutput
> {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @see {@link https://ux.stackexchange.com/questions/95336/how-long-should-the-debounce-timeout-be}
   */
  defaultDebounceTime() {
    return 500;
  }

  onEnter() {
    if (this.input.enterAction) {
      this.dispatchActionsPromise(this.input.enterAction);
    }
  }

  onEscape() {
    if (this.input.escapeAction) {
      this.dispatchActionsPromise(this.input.escapeAction);
    }
  }
}
