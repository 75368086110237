import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { bufferTime, filter, map } from 'rxjs/operators';
import {
  WidgetDeregistration,
  WidgetRegistration,
} from '@app/models/widget-registration.model';
import * as WidgetActions from '@app/state/widgets/widgets.actions';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private readonly _registrations$ = new Subject<WidgetRegistration>();
  private readonly _deregistrations$ = new Subject<WidgetDeregistration>();

  constructor(private readonly store: Store) {
    this._registrations$
      .pipe(
        bufferTime(0),
        filter(actions => actions.length > 0),
        map(actions => new WidgetActions.RegisterWidgets(actions))
      )
      .subscribe(action => this.store.dispatch(action));
    this._deregistrations$
      .pipe(
        bufferTime(0),
        filter(actions => actions.length > 0),
        map(actions => new WidgetActions.DeregisterWidgets(actions))
      )
      .subscribe(action => this.store.dispatch(action));
  }

  public register(registration: WidgetRegistration) {
    this._registrations$.next(registration);
  }

  public deregister(deregistration: WidgetDeregistration) {
    this._deregistrations$.next(deregistration);
  }
}
