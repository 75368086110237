import { Injectable } from '@angular/core';
import { getActionTypeFromInstance, NgxsPlugin, setValue } from '@ngxs/store';
import { merge } from 'lodash-es';
import {
  SetGlobalContextProperty,
  UpdateGlobalContext,
} from '@app/state/widgets/widgets.actions';

@Injectable()
export class NgxsWidgetPluginService implements NgxsPlugin {
  handle(
    state: any,
    event: any,
    next: (state: any, mutation: any) => any
  ): any {
    const type = getActionTypeFromInstance(event);
    let nextState = state;

    if (type === UpdateGlobalContext.type) {
      const globalContextEvent = event as UpdateGlobalContext;
      nextState = merge({}, nextState, globalContextEvent.context);
      nextState = setValue(
        nextState,
        'widgets.lastGlobalActionResult',
        globalContextEvent.context
      );
    } else if (type === SetGlobalContextProperty.type) {
      const setGlobalContextPropertyEvent = event as SetGlobalContextProperty;
      nextState = setValue(
        nextState,
        setGlobalContextPropertyEvent.context.path,
        setGlobalContextPropertyEvent.context.value
      );
      nextState = setValue(
        nextState,
        'widgets.lastGlobalActionResult',
        setGlobalContextPropertyEvent.context
      );
    }

    return next(nextState, event);
  }
}
