import { isEqual } from 'lodash-es';
import type {
  DistinctUntilChangedExpression,
  Resolvable,
} from '@trackback/widgets';
import { isExpression } from '@trackback/widgets';
import { DataChangeCallback, ParseOptions, Parser } from '../parser';
import { NamedResolver } from '../types';

export const distinctUntilChangedResolver: NamedResolver<DistinctUntilChangedExpression> =
  Object.assign(
    <T>(
      parser: Parser,
      options: ParseOptions,
      onData: DataChangeCallback<T>,
      [input]: [Resolvable<T>]
    ) => {
      if (isExpression(input)) {
        let lastData: T;
        let firstEmit = true;
        return parser.parseExpression(
          input,
          {
            error: onData.error,
            next: parsedInput => {
              if (firstEmit || !isEqual(lastData, parsedInput)) {
                firstEmit = false;
                lastData = parsedInput as T;
                return onData.next(parsedInput as T);
              }
            },
          },
          options
        );
      } else {
        return onData.next(input as T);
      }
    },
    { id: 'distinctUntilChanged' as const }
  );
