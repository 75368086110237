import { Injectable, Injector } from '@angular/core';
import { flatten, isEqual } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { finalize, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { WidgetDefinitionTuple } from '@app/models/widget-input.model';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from '../structural-widgets';
import { WidgetResolver } from '@app/structural-widgets/widget-resolver';
import { StructuralInput, WidgetInput } from '@trackback/widgets';
import { getType, isTypeInGroup } from '@app/utils/type-registry';
import {
  AppError,
  DEFAULT_ERROR_TRANSLATION_KEYS,
} from '@app/models/error.model';

@Injectable({
  providedIn: 'root',
})
export class WidgetResolverService {
  constructor(private readonly injector: Injector) {}

  resolve(
    tuple$: Observable<WidgetDefinitionTuple>
  ): Observable<WidgetDefinitionTuple[]> {
    let activeResolver: WidgetResolver<WidgetInput>;
    return tuple$.pipe(
      startWith([undefined, undefined]),
      pairwise(),
      switchMap(([[previousInput], [input, context]]) => {
        if (!isEqual(input, previousInput)) {
          if (activeResolver) {
            activeResolver.disconnect();
            activeResolver = null;
          }
          if (this.isStructuralWidget(input)) {
            activeResolver = this.createWidgetResolver(input);
            activeResolver.connect();
          }
        }
        if (activeResolver) {
          return activeResolver.getState(context).pipe(
            switchMap(subTuples =>
              subTuples.length
                ? combineLatest(
                    subTuples.map(subTuple => this.resolve(of(subTuple)))
                  )
                : of([])
            ),
            map(flatten)
          );
        } else {
          return of([[input, context] as WidgetDefinitionTuple]);
        }
      }),
      finalize(() => activeResolver && activeResolver.disconnect())
    );
  }

  createWidgetResolver(input?: StructuralInput<never>) {
    if (input && this.isStructuralWidget(input)) {
      try {
        const ClassConstructor = getType<WidgetResolver<never>>(input.type);
        return new ClassConstructor(input, this.injector);
      } catch (e) {
        console.error(e);
        throw new AppError(
          'widgets/resolver-creation-failure',
          DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR,
          e.developerMessage || e.message
        );
      }
    }
    return new WidgetResolver<WidgetInput>(input, this.injector);
  }

  isStructuralWidget(input?: WidgetInput): input is StructuralInput<never> {
    return !!input && isTypeInGroup(input.type, STRUCTURAL_WIDGET_TYPE_GROUP);
  }
}
