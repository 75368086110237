import { Pipe, PipeTransform } from '@angular/core';
import { ParserService } from '@app/services/parser.service';
import { ParseOptions } from '@app/expressions/parser';

@Pipe({
  name: 'parse',
  pure: true,
  standalone: true,
})
export class ParsePipe implements PipeTransform {
  constructor(private readonly parser: ParserService) {}

  transform<T>(
    value: T,
    context: Record<string, unknown>,
    options?: ParseOptions
  ) {
    return this.parser.parse(value, {
      context,
      ...options,
    });
  }
}
