// The actual configurations are defined as per environment (e.g. dev, prod, test) in `/src/environments`

import { InjectionToken } from '@angular/core';

export interface AppConfigModel {
  /**
   * Should match the id of the Application how it is set up in the `single_signon` database.
   */
  readonly APP_ID: number;

  /**
   * The base url under which the application is deployed (e.g. `/admin-tools`).
   */
  readonly BASE_HREF: string;

  /**
   * The app config file `/src/app/app.config.ts` contains a helper definition for the api url.
   *
   * It is based on the assumpted that:
   * 1. Each application has exactly one api associated with it
   * 2. In production, the url format is `app.<domain>.<superdomain>/<app-name>` for the web application and `api.<domain>.<superdomain>/<app-name>`
   * for the api.
   * 3. In development mode, the api is hosted on the same machine on the port `32<appId>` (e.g. Insight with id 8 would be port 3208).
   * 4. A temporary solution is added for mobile reporting whereby the url format is `mobile.<domain>.<superdomain>/<app-name>` for the web application and `api.<domain>.<superdomain>/mobile/<app-name>`
   * for the api. This will no longer be required when Insight 4 desktop version is available and both mobile / desktop reporting will share teh same domain as `app.<domain>.<superdomain>/<app-name>`
   *
   * No further configuration is necessary to determine the API url.
   */
  readonly API_URL: string;

  /**
   * Which protocol to use when connecting to the api.
   */
  readonly API_PROTOCOL?: 'http' | 'https';

  /**
   * How long notifications (snackbars) should be displayed until automatically disappearing.
   */
  readonly DEFAULT_NOTIFICATION_DURATION: number;

  /**
   * Whether the application is in production mode.
   * When active, debugging capabilities will be limited to improve performance.
   */
  readonly PRODUCTION: boolean;

  /**
   * Further optional properties may be defined.
   */
  readonly [key: string]: any;
}

/**
 * @param host The hostname (usually `location.hostname`)
 * @param appId The application id to determine the local port in development mode
 * @param baseHref The base href for production builds
 */
export function getApiUrl(
  host: string,
  appId: number,
  baseHref: string
): string {
  switch (true) {
    case host.indexOf('app') !== -1:
      return `${host.replace('app', 'api')}${baseHref}`;
    case host.indexOf('mobile') !== -1:
      return `${host.replace('mobile', 'api')}/mobile${baseHref}`;
    case host === 'localhost' ||
      host === '127.0.0.1' ||
      host.indexOf('10.100.0.1') > -1 ||
      host.indexOf('192.168.1.') > -1: {
      const apiUrl = `127.0.0.1:32${appId > 9 ? appId : `0${appId}`}`;
      console.warn(`You are in dev mode. Current api path: ${apiUrl}`);
      return apiUrl;
    }
    default:
      console.error(
        `Host sub-domain must contain "app" or "localhost" but it doesn't`
      );
      return host + '/';
  }
}

export const APP_CONFIG = new InjectionToken<AppConfigModel>(
  '@trackback/ng-common.app-config'
);
