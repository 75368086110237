import type { GlobalExpression } from '@trackback/widgets';
import { Observable } from 'rxjs';
import { createNoArgsObservableNamedExpressionResolver } from '../types';
import { DefaultGlobal } from '@trackback/widgets/build/main/expressions/functions/global.definition';

export const createGlobalResolver = <T extends DefaultGlobal>(
  global$: Observable<T>
) =>
  createNoArgsObservableNamedExpressionResolver<GlobalExpression<T>>(
    'global',
    global$
  );
